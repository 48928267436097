<template>
  <app-form
    :value="value"
    :showModal="showModal"
    :showCreateProjectModal="showCreateProjectModal"
    @show="showAddEndpointModal"
    @project="startCreateProjectModal"
    @submitProject="createProject"
    @submit="createApp"
  />
</template>

<script>
import AppForm from '@/components/apps/AppForm.vue'
export default {
  name: 'CreateApp',
  components: {
    'app-form': AppForm,
  },
  data() {
    return {
      view: 'addAppDetails',
      showModal: this.$route.meta.showModal,
      showCreateProjectModal: this.$route.meta.showCreateProjectModal,
      value: {
        name: '',
        description: '',
        project: null,
        envars: [],
      },
    }
  },
  created() {
    this.$store.dispatch('app/getProjects')
  },
  methods: {
    showAddEndpointModal() {
      this.$router.push({ name: 'addEndpoint' })
    },
    startCreateProjectModal() {
      this.$router.push({ name: 'addAppProject' })
    },
    createApp() {
      this.isSubmitting = true
      this.$store
        .dispatch('app/createApp', this.value)
        .then(() => {
          this.$router.push({ name: 'apps' })
          this.clearState()
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    createProject(payload) {
      this.$store.dispatch('app/createProject', payload).then(() => {
        this.$router.go(-1)
      })
    },
  },
  watch: {
    '$route.meta'({ showModal, showCreateProjectModal }) {
      this.showModal = showModal
      this.showCreateProjectModal = showCreateProjectModal
    },
  },
}
</script>
<style>
.img {
  height: 300px;
}
.bg-white {
  background: white;
}
</style>
